<style lang="scss">
$border-color: #e9eaeb;
.linkedsortinggrid {
  text-align: center;
  .col-2 {
    border:1px solid $border-color;
    border-right:0;
    border-bottom:0;
    &:nth-child(6), &:nth-child(12) ,&:nth-child(18), &:nth-child(24), &:nth-child(29) {
      border-right:1px solid $border-color;
    }
    &:nth-child(24), &:nth-child(25), &:nth-child(26), &:nth-child(27), &:nth-child(28), &:nth-child(29) {
      border-bottom:1px solid $border-color;
    }
  }
  
  .mitarbeiter {
    
    cursor: pointer;
    transition: 0.2s all;
    padding: 20px;
    height: 100%;
   
   
    &:hover {
      background-color: $border-color;
    }

    .name {
      font-weight: bold;
    }

    .avatar {
      margin: 0 auto;
      height: 50px;
      width: 50px;
      border-radius: 50%;
      background-position: center;
      background-size: cover;
    }
  }
}
// .table {
//   width: 100%;
//   border-spacing: 0;
//   padding-top: 10px;
//   @media (max-width: 1380px) {
//     font-size: 90%;
//   }

//   tr {
//     cursor: pointer;
//     transition: 0.2s all;

//     td {
//       padding: 5px;
//       text-align: left;

//       img {
//         border: 1px solid red;
//       }
//     }
//     &:nth-child(odd) {
//       background: #f9f9f9;
//     }
//     &:hover {
//       background-color: $c-smd-lachs;
//       color: #fff;
//       td {
//         &:first-child {
//           background-color: none;
//         }
//       }
//     }
//   }

//   th {
//     &:first-child {
//       margin-top: -1px;
//       position: relative;
//       top: 0px;
//     }
//     text-align: left;
//     font-family: 'HelveticaNeueLTPro-lt';
//     text-transform: uppercase;
//     padding: 15px 5px;
//     height: 30px !important;
//     border-bottom: 1px solid #eee;
//     letter-spacing: 1px;
//     font-size: 90%;
//   }
// }

// .dived {
//   .row {
//     cursor: pointer;
//     border: 1px solid black;
//     margin-bottom: 2px;
//     padding: 5px;
//     &:hover {
//       background-color: #000;
//       color: #fff;
//     }

//     .rowcontent {
//       display: flex;

//       .label {
//         width: 30%;
//         font-weight: bold;
//       }

//       .content {
//         margin-left: 5px;
//       }
//     }
//   }
// }
</style>

<template>
  <div class="container linkedsortinggrid">
    <div class="row gy-0 gx-0">
      <router-link :to="item.link" :key="i" v-for="(item, i) in items" custom v-slot="{ navigate }">
        <div class="col-2" @click="navigate">
          <div class="mitarbeiter">
            <div v-html="item.avatarframe"></div>
            <div class="name">{{ item.nameFirst }} {{ item.nameLast }}</div>
            <div class="team">{{ item.team }}</div>
            <div class="position" v-if="item.position">{{ item.position }}</div>
          </div>
        </div>
      </router-link>
    </div>
  </div>
  <!-- <table class="table" v-if="!mobile">
    <th :key="i" v-for="(header, i) in headers">
      {{ header.label }}
    </th>
    <router-link :to="item.link" :key="i" v-for="(item, i) in items" custom v-slot="{ navigate }">
      <tr @click="navigate">
        <td :key="i" v-for="(header, i) in headers">
          <div v-html="item[header.key]" v-if="header.type == 'raw'"></div>
          <span v-else>{{ item[header.key] }}</span>
        </td>
      </tr>
    </router-link>
  </table>
  <div class="dived" v-else>
    <router-link :to="item.link" :key="item['_id']" v-for="item in items" custom v-slot="{ navigate }">
      <div class="row" @click="navigate">
        <div class="rowcontent" :key="header.key" v-for="header in headers">
          <div class="label">{{ header.label }}</div>
          <div class="content">{{ item[header.key] }}</div>
        </div>
      </div>
    </router-link>
  </div> -->
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useStore } from 'vuex';
export default defineComponent({
  props: {
    items: {
      type: Array,
      requried: true,
    },
  },
  setup() {
    const store = useStore();
  },
});
</script>
