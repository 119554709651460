
import { defineComponent } from 'vue';
import { useStore } from 'vuex';
export default defineComponent({
  props: {
    items: {
      type: Array,
      requried: true,
    },
  },
  setup() {
    const store = useStore();
  },
});
