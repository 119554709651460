
import { AxiosResponse } from 'axios';
import { defineComponent, ref, computed } from 'vue';
import { useStore } from 'vuex';
import LinkedSortingGrid from '../../components/LinkedSortingGrid.vue';

export default defineComponent({
  components: {
    LinkedSortingGrid,
  },
  setup() {
    const store = useStore();
    const users = ref([]);
    const filter = ref('');

    store.dispatch('API', { method: 'get', url: '/user/list' }).then((res: AxiosResponse) => {
      users.value = res.data
        .filter((item: any) => !item.invisible)
        .map((item: any) => {
          item.link = {
            name: 'Profil',
            params: { id: item['_id'] },
          };
          let avatar = store.getters['getAvatarUrl'](item.avatar);
          item.avatarframe = `<div class='avatar' style='background-image: url(${avatar})' />`;
          item.fullname = `${item.nameFirst} ${item.nameLast}`;

          return item;
        })
        .sort((a: any, b: any) => {
          if (a.nameLast < b.nameLast) return -1;
          if (a.nameLast > b.nameLast) return 1;
          return 0;
        });
    });

    const usersfiltered = computed(() => {
      return users.value.filter((item: any) => {
        return (
          item.nameFirst.toLowerCase().includes(filter.value.toLowerCase()) ||
          item.nameLast.toLowerCase().includes(filter.value.toLowerCase()) ||
          item.login.toLowerCase().includes(filter.value.toLowerCase()) ||
          (item.email && item.email.toLowerCase().includes(filter.value.toLowerCase())) ||
          (item.location && item.location.toLowerCase().includes(filter.value.toLowerCase())) ||
          (item.team && item.team.toLowerCase().includes(filter.value.toLowerCase())) ||
          (item.role && item.role.toLowerCase().includes(filter.value.toLowerCase()))
        );
      });
    });

    return {
      filter,
      usersfiltered,
    };
  },
});
