
import { AxiosResponse } from 'axios';
import { defineComponent, ref, computed } from 'vue';
import { useStore } from 'vuex';
import LinkedSortingTable from '../../../components/LinkedSortingTable.vue';

export default defineComponent({
  components: {
    LinkedSortingTable,
  },
  setup() {
    const store = useStore();
    const filter = ref('');
    const users = ref([]);
    const tableheaders = [
      { label: '', key: 'avatarframe', type: 'raw' },
      {
        label: 'Name',
        key: 'fullname',
      },
      {
        label: 'Login',
        key: 'login',
      },
      {
        label: 'Team',
        key: 'team',
      },
      {
        label: 'Standort',
        key: 'location',
      },
      {
        label: 'Email',
        key: 'email',
      },

      {
        label: 'Rolle',
        key: 'role',
      },
    ];

    const roleName = (role: number) => store.getters['getRoleName'](role);

    store.dispatch('API', { method: 'get', url: '/user/list' }).then((res: AxiosResponse) => {
      res.data.map((item: any) => {
        item.role = roleName(item.role);
        item.link = {
          name: 'Mitarbeiter Bearbeiten',
          params: { id: item['_id'] },
        };
        let avatar = store.getters['getAvatarUrl'](item.avatar);
        item.avatarframe = `<div class='avatar' style='background-image: url(${avatar})' />`;
        item.fullname = `${item.nameFirst} ${item.nameLast}`;
      });

      users.value = res.data;
    });

    const usersfiltered = computed(() => {
      return users.value.filter((item: any) => {
        return (
          item.nameFirst.toLowerCase().includes(filter.value.toLowerCase()) ||
          item.nameLast.toLowerCase().includes(filter.value.toLowerCase()) ||
          item.login.toLowerCase().includes(filter.value.toLowerCase()) ||
          (item.email && item.email.toLowerCase().includes(filter.value.toLowerCase())) ||
          (item.location && item.location.toLowerCase().includes(filter.value.toLowerCase())) ||
          (item.team && item.team.toLowerCase().includes(filter.value.toLowerCase())) ||
          (item.role && item.role.toLowerCase().includes(filter.value.toLowerCase()))
        );
      });
    });

    return {
      tableheaders,
      usersfiltered,
      filter,
      mobile: false,
    };
  },
});
