<style lang="scss">
#mitarbeiter {
  .filter {
    padding-top: 25px;
  }
  .btn {
    left:10px;
  }
  .table {
    margin-top: 20px;
    .avatar {
      background-size: cover;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin: 0 auto;
      background-position: center center;
    }
  }

  .flex-none-small {
    @media (max-width: 600px) {
      display: block;
    }
    .cols {
      @media (max-width: 600px) {
        margin-bottom: 20px;
      }
    }
  }
  .btn-form-benutzer {
    padding: 10px 15px;
    height: auto;
  }

  .search-btn-all {
    position: relative;
    display: block;
    width: 100%;
    .form-element {
      margin-top: 0;
    }
    .search-icon {
      position: absolute;
      right: 15px;
      top: 50%;
      margin-top: -10px;
      color: #ccc;
      font-size: 140%;
      transition: all 0.4s ease;
    }
    &:focus-within {
      .search-icon {
        transform: rotate(360deg);
        color: #666;
      }
    }
  }
}
</style>

<template>
  <div id="mitarbeiter">
    <div class="row align-items-centerfilter">
      <div class="col-3">
        <router-link class="btn blue" :to="{ name: 'Mitarbeiter Erstellen' }">Neuen Benutzer erstellen</router-link>
      </div>
      <div class="col-9">
        <div class="row">
          <div class="cols-lg-8 cols-md-7 cols-sm-12 cols-xs-12"></div>
          <div class="cols-lg-4 cols-md-5 cols-sm-12 cols-xs-12">
            <div class="search-btn-all">
              <div class="form-element">
                <label for="filter" :class="{ hasvalue: filter != '' }">Suche</label>
                <input class="form-control" type="text" id="filter" v-model="filter" placeholder="Suche nach Name, Standort, Team..." />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <LinkedSortingTable :headers="tableheaders" :items="usersfiltered" :mobile="mobile" />
  </div>
</template>

<script lang="ts">
import { AxiosResponse } from 'axios';
import { defineComponent, ref, computed } from 'vue';
import { useStore } from 'vuex';
import LinkedSortingTable from '../../../components/LinkedSortingTable.vue';

export default defineComponent({
  components: {
    LinkedSortingTable,
  },
  setup() {
    const store = useStore();
    const filter = ref('');
    const users = ref([]);
    const tableheaders = [
      { label: '', key: 'avatarframe', type: 'raw' },
      {
        label: 'Name',
        key: 'fullname',
      },
      {
        label: 'Login',
        key: 'login',
      },
      {
        label: 'Team',
        key: 'team',
      },
      {
        label: 'Standort',
        key: 'location',
      },
      {
        label: 'Email',
        key: 'email',
      },

      {
        label: 'Rolle',
        key: 'role',
      },
    ];

    const roleName = (role: number) => store.getters['getRoleName'](role);

    store.dispatch('API', { method: 'get', url: '/user/list' }).then((res: AxiosResponse) => {
      res.data.map((item: any) => {
        item.role = roleName(item.role);
        item.link = {
          name: 'Mitarbeiter Bearbeiten',
          params: { id: item['_id'] },
        };
        let avatar = store.getters['getAvatarUrl'](item.avatar);
        item.avatarframe = `<div class='avatar' style='background-image: url(${avatar})' />`;
        item.fullname = `${item.nameFirst} ${item.nameLast}`;
      });

      users.value = res.data;
    });

    const usersfiltered = computed(() => {
      return users.value.filter((item: any) => {
        return (
          item.nameFirst.toLowerCase().includes(filter.value.toLowerCase()) ||
          item.nameLast.toLowerCase().includes(filter.value.toLowerCase()) ||
          item.login.toLowerCase().includes(filter.value.toLowerCase()) ||
          (item.email && item.email.toLowerCase().includes(filter.value.toLowerCase())) ||
          (item.location && item.location.toLowerCase().includes(filter.value.toLowerCase())) ||
          (item.team && item.team.toLowerCase().includes(filter.value.toLowerCase())) ||
          (item.role && item.role.toLowerCase().includes(filter.value.toLowerCase()))
        );
      });
    });

    return {
      tableheaders,
      usersfiltered,
      filter,
      mobile: false,
    };
  },
});
</script>
