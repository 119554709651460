<style lang="scss">
#mitarbeiter {
  .linkedsortinggrid {
    margin-top: 50px;
  }
  .table {
    margin-top: 20px;
    .avatar {
      background-size: cover;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin: 0 auto;
      background-position: center center;
    }
  }
  .flex-none-small {
    @media (max-width: 900px) {
      display: block;
    }
    .cols {
      @media (max-width: 900px) {
        margin-bottom: 20px;
      }
    }
  }
  .btn-form-benutzer {
    padding: 10px 15px;
    height: auto;
  }

  .search-btn-all {
    position: relative;
    display: block;
    width: 100%;
    .search-btn {
      .w-input__input-wrap {
        height: auto;
        border-bottom: 0;
        .w-input__input {
          border: 1px solid #eee;
          height: auto;
          padding: 12px 45px 12px 15px;
          color: #212121;
          &:focus {
            border: 1px solid $c-smd-lachs;
          }
          &::placeholder {
            color: #666;
            font-size: 90%;
            letter-spacing: 1px;
          }
        }
        &:after {
          border-bottom: 2px solid $c-smd-lachs;
        }
      }
    }
    .search-icon {
      position: absolute;
      right: 15px;
      top: 50%;
      margin-top: -10px;
      color: #ccc;
      font-size: 140%;
      transition: all 0.4s ease;
    }
    &:focus-within {
      .search-icon {
        transform: rotate(360deg);
        color: #666;
      }
    }
  }
}
</style>

<template>
  <div id="mitarbeiter">
    <div class="row headline">
      <h1 class="col-12">Mitarbeiter</h1>
      <h2 class="col-12">der Styria Media Design</h2>
    </div>
    <!-- <div class="row">
      <div class="cols-lg-8 cols-md-7 cols-sm-12 cols-xs-12"></div>
      <div class="cols-lg-4 cols-md-5 cols-sm-12 cols-xs-12">
        <div class="search-btn-all">
          <div class="form-element">
            <label for="filter" :class="{ hasvalue: filter != '' }">Suche</label>
            <input class="form-control" type="text" id="filter" v-model="filter" placeholder="Suche nach Name, Standort, Team..." />
          </div>
        </div>
      </div>
    </div> -->
    <LinkedSortingGrid :items="usersfiltered" />
  </div>
</template>

<script lang="ts">
import { AxiosResponse } from 'axios';
import { defineComponent, ref, computed } from 'vue';
import { useStore } from 'vuex';
import LinkedSortingGrid from '../../components/LinkedSortingGrid.vue';

export default defineComponent({
  components: {
    LinkedSortingGrid,
  },
  setup() {
    const store = useStore();
    const users = ref([]);
    const filter = ref('');

    store.dispatch('API', { method: 'get', url: '/user/list' }).then((res: AxiosResponse) => {
      users.value = res.data
        .filter((item: any) => !item.invisible)
        .map((item: any) => {
          item.link = {
            name: 'Profil',
            params: { id: item['_id'] },
          };
          let avatar = store.getters['getAvatarUrl'](item.avatar);
          item.avatarframe = `<div class='avatar' style='background-image: url(${avatar})' />`;
          item.fullname = `${item.nameFirst} ${item.nameLast}`;

          return item;
        })
        .sort((a: any, b: any) => {
          if (a.nameLast < b.nameLast) return -1;
          if (a.nameLast > b.nameLast) return 1;
          return 0;
        });
    });

    const usersfiltered = computed(() => {
      return users.value.filter((item: any) => {
        return (
          item.nameFirst.toLowerCase().includes(filter.value.toLowerCase()) ||
          item.nameLast.toLowerCase().includes(filter.value.toLowerCase()) ||
          item.login.toLowerCase().includes(filter.value.toLowerCase()) ||
          (item.email && item.email.toLowerCase().includes(filter.value.toLowerCase())) ||
          (item.location && item.location.toLowerCase().includes(filter.value.toLowerCase())) ||
          (item.team && item.team.toLowerCase().includes(filter.value.toLowerCase())) ||
          (item.role && item.role.toLowerCase().includes(filter.value.toLowerCase()))
        );
      });
    });

    return {
      filter,
      usersfiltered,
    };
  },
});
</script>
